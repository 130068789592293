@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media (max-width: 480px) {
  @keyframes fadeOut {
    from {
      bottom: 0;
      opacity: 1;
    }

    to {
      bottom: -100vh;
      opacity: 0;
    }
  }
}

.closeModal {
  animation: fadeOut 0.5s ease forwards !important;
}
